interface CustomError extends Error {
  status?: number
  data?: unknown
}

export const createBody = (
  data: Record<string, unknown>,
  method: 'POST' | 'PUT' | 'DELETE' = 'POST'
) => ({
  method,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data),
})

const fetchJson = async <T = any>(
  url: RequestInfo,
  init?: RequestInit
): Promise<T> => {
  const response = await fetch(url, init)
  const data = await response.json()

  if (!response.ok) {
    const error: CustomError = new Error(response.statusText)
    error.data = data
    error.status = response.status
    throw error
  }

  return data
}

export default fetchJson
